import React from 'react';

import { vegaDark, vegaLight, VegaMuiThemeTypes } from 'theme/theme';

export type app = {
  name: string;
  iconUrl: string;
  description: string;
  navLinkUrl: string;
  code: string;
  isAssigned: boolean;
  ordinal: number;
};

export type AvailableAppsResult = {
  applications: Array<ApplicationList>;
};

export type ApplicationList = {
  name: string;
  availableApplications: Array<app>;
};

export enum ApplicationCode {
  DevPortal = 'DevPortal',
  BillPayVT = 'BillPayVT',
  InfoCentral = 'InfoCentral',
  BillPayMerchantView = 'BillPay',
  PorticoVT = 'PorticoVT',
  PaymentsManagerPlus = 'PaymentsManagerPlus',
  PorticoGateway = 'PorticoGateway',
  ACHReporting = 'ACHReporting',
  Payroll = 'Payroll',
  PayrollHiringAndOnboarding = 'PayrollHiring&Onboarding',
  PayrollTimeAndAttendance = 'PayrollTime&Attendance',
  PayrollBenefitsAdministration = 'PayrollBenefitsAdministration',
}

export enum BackgroundTypes {
  Circle = 'Circle',
  Default = 'Default',
}

export enum TransitionTypes {
  Fade = 'Fade',
}

export enum LayoutTypes {
  Card = 'Card',
  CardTransparent = 'CardTransparent',
}

export enum CardHeaderTypes {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum DeviceTypes {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}

export enum AppThemeTypes {
  Light = 'light', //Matches VegaMuiThemeTypes.Light
  Dark = 'dark', //Matches VegaMuiThemeTypes.Dark
  System = 'system',
}

export type ResultMessage = {
  type: ResultMessageType;
  message: ResultMessageText;
};

export enum ResultMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO =  'info',
}

export enum ResultMessageTextUntranslated {
  SUCCESS_ROLE_MODIFY = 'Successfully modified user role',
  FAILURE_ROLE_MODIFY = 'Failed to modify user role. Please try again.',
  SUCCESS_DEACTIVATE_USER = 'Successfully deactivated user.',
  FAILURE_DEACTIVATE_USER = 'Failed to deactivate user. Please try again.',
  SUCCESS_ACTIVATE_USER = 'Successfully activated user.',
  FAILURE_ACTIVATE_USER = 'Failed to activate user. Please try again.',
  INVITE_USER = 'Successfully invited user.',
  RESENT_INVITE = 'Resent user invitation.',
  ALREADY_ASSIGNED = 'Failed to invite user because user already exists at this location.',
  FAIL_TO_INVITE = 'Failed to invite user. Please try again.',
  NOT_SIGNER_SGA = 'Because you are not a signer for this location, you cannot sign up for this product.',
  NOT_SIGNER_ACCOUNT_SETTINGS = 'Because you are not a signer for this location, you cannot make an account change request.',
  IMPERSONATE_MODE_ERROR = 'Account changes cannot be performed in impersonation mode.',
}

export enum ResultMessageText {
  SUCCESS_ROLE_MODIFY = 'SUCCESS_ROLE_MODIFY',
  FAILURE_ROLE_MODIFY = 'FAILURE_ROLE_MODIFY',
  SUCCESS_DEACTIVATE_USER = 'SUCCESS_DEACTIVATE_USER',
  FAILURE_DEACTIVATE_USER = 'FAILURE_DEACTIVATE_USER',
  SUCCESS_ACTIVATE_USER = 'SUCCESS_ACTIVATE_USER',
  FAILURE_ACTIVATE_USER = 'FAILURE_ACTIVATE_USER',
  INVITE_USER = 'INVITE_USER',
  RESENT_INVITE = 'RESENT_INVITE',
  ALREADY_ASSIGNED = 'ALREADY_ASSIGNED',
  FAIL_TO_INVITE = 'FAIL_TO_INVITE',
  NOT_SIGNER_SGA = 'NOT_SIGNER_SGA',
  NOT_SIGNER_ACCOUNT_SETTINGS = 'NOT_SIGNER_ACCOUNT_SETTINGS',
  IMPERSONATE_MODE_ERROR = 'IMPERSONATE_MODE_ERROR',
  GENERIC_ERROR = 'An error occurred.',
}

export interface AppContextInterface {
  appBackground: BackgroundTypes;
  theme: VegaMuiThemeTypes;
  cartPopup: boolean;
  expandedSideNav: boolean;
  handleNavSet: (value: boolean) => void;
  setCartPopup: (show: boolean) => void;
  setAppBackground: (background: BackgroundTypes) => void;
  handleCartPopover: () => void;
  setTheme: (theme: VegaMuiThemeTypes) => void;
}

const AppContext = React.createContext<AppContextInterface>({
  appBackground: BackgroundTypes.Default,
  theme: VegaMuiThemeTypes.Light,
  cartPopup: false,
  expandedSideNav: true,
  handleNavSet: () => {},
  setCartPopup: () => {},
  setAppBackground: () => {},
  setTheme: () => {},
  handleCartPopover: () => {},
});

export { AppContext };

export const SideNavKey = 'SideNavExpanded';
export const MuiErrorClass = '.Mui-error';
export const TemplateKey = 'Templates';
export const OwnerPaymentsTemplate = 'Owner Payments Account';
export const OwnerPayrollTemplate = 'Owner Payroll Account';
export const UnverifiedTemplate = 'Unverified';

export const ThemeKey = 'Theme';
export const ChartDateKey = 'GraphDateKey';

export const LoadedVersionKey = 'LoadedVersion';

export const darkBG = vegaDark.BgPage;
export const lightBG = vegaLight.BgPage;

export const primaryLight = vegaLight.BgAction;
export const primaryDark = vegaDark.BgAction;

export const primaryAccentLight = '#FCFCFC';
export const primaryAccentDark = '#02020c';

export const dividerDark = '#203645';
export const dividerLight = '#abc6d8';

export const shadowLight = '0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)';
export const shadowDark = '0px 2px 10px 0px rgba(0, 0, 0, 0.18), 0px 2px 4px 0px rgba(0, 0, 0, 0.1)';

export const shadowPopoverLight =
  '0px 4px 72px rgba(2, 2, 15, 0.06), 0px 0px 32px rgba(2, 2, 15, 0.02), 0px 0px 88px rgba(2, 2, 15, 0.01)';
export const shadowPopoverDark =
  '0px 4px 72px rgba(2, 2, 15, 0.06), 0px 0px 32px rgba(2, 2, 15, 0.02), 0px 0px 88px rgba(2, 2, 15, 0.01);';

export const blank = 'BLANK';

export const PRIVACY_POLICY = 'https://www.heartlandpaymentsystems.com/privacy-policy';

export const NotAuthorizedBannerText =
  'Your account does not have permission to purchase equipment, but you may still view equipment details and pricing. If you think you should have access to make purchases, please contact the owner or the company admin.';

export const switchToPaymentsText = 'Please switch to an active Payments account to view this page.';

export const changingLocationText = 'Changing Location...';

export const zendeskURL = 'https://heartlandmyaccount.zendesk.com/hc/en-us/';

export const ReservedImpersonateModeError = 'User not allowed because view only is active.';
export const ReservedImpersonateModeMessage = 'You are not allowed to perform this action during a view only session.';
export const ReservedInteractionRequiredAuthError = 'InteractionRequiredAuthError';
export const GenericApiError = 'There was an error submitting your request. Please try again later.';
export const ReservedAuthNotAuthorizedError = 'AUTH_NOT_AUTHORIZED';
